<h2 mat-dialog-title>
    <span class="flex flex-grow">{{ dialogData.dialogTitle }}</span>
    <mat-icon (click)="onCancel()">close</mat-icon>
</h2>

<mat-dialog-content *ngIf="dialogData?.componentToLoad">
    <div *ngIf="isProcessing" 
        class="loader-wrapper">
        <qpx-loader></qpx-loader>
    </div>
    
    <ng-container *ngComponentOutlet="dialogData.componentToLoad; inputs: dialogData.componentToLoadData">
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <div *ngIf="!dialogData.hideCancelButton">
        <button mat-stroked-button
                color="primary"
                [disabled]="isProcessing"
                (click)="onCancel()">
            <mat-icon class="qpx-icon-sm">
                close
            </mat-icon>
            {{ dialogData.cancelButtonLabel || 'Cancel' }}
        </button>
    </div>

    <button mat-stroked-button
            [color]="dialogData.submitButtonTheme ? dialogData.submitButtonTheme : 'primary'"
            [disabled]="isProcessing || !canConfirm"
            (click)="onConfirm()">
        <mat-icon *ngIf="dialogData.submitButtonIcon"
                  class="qpx-icon-sm">
            {{ dialogData.submitButtonIcon }}
        </mat-icon>
        {{ dialogData.submitButtonLabel || 'Confirm' }}
    </button>

</mat-dialog-actions>