import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import { Observable } from 'rxjs';
import { IState } from '../models/typings/state.interface';

@Injectable({
    providedIn: 'root',
  })
export class MiscApiService extends ApiServiceBase {
    private apiBuildingBase = `${this.appConfig.apiBase}/api/buildings`;
    private apiCountryBase = `${this.appConfig.apiBase}/api/countries`;

  fetchStates(countryId: number | null = null):Observable<IState[]> {
    const url = `${this.apiCountryBase}/${countryId ?? ''}/states`;
    return this.http.get<any>(url).pipe(
        map((response) => response),
        catchError(this.handleError)
    );
  }

  fetchClasses() {
    const url = `${this.apiBuildingBase}/classes`;
    return this.http.get<any>(url).pipe(
        map((response) => response),
        catchError(this.handleError)
    );
  }
  
}
