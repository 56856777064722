export enum EsmCategoryEnum {
    FIRE_PROTECTION_SYSTEMS_EQUIPMENT = 10,
    EMERGENCY_LIGHTING_EXIT_SIGNS = 20,
    AIR_HANDLING_WATER_SYSTEMS = 30,
    CUSTOM = 40
}

export enum EsmCategoryNamesEnum {
    FIRE_PROTECTION_SYSTEMS_EQUIPMENT = 'Fire protection systems & equipment',
    EMERGENCY_LIGHTING_EXIT_SIGNS = 'Emergency lighting & exit signs',
    AIR_HANDLING_WATER_SYSTEMS = 'Air-handling & water systems',
    CUSTOM = 'Other'
}


export const esmCategoryyOptions: { id: EsmCategoryEnum, name: EsmCategoryNamesEnum }[] = [
    { id: EsmCategoryEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT, name: EsmCategoryNamesEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT },
    { id: EsmCategoryEnum.EMERGENCY_LIGHTING_EXIT_SIGNS, name: EsmCategoryNamesEnum.EMERGENCY_LIGHTING_EXIT_SIGNS },
    { id: EsmCategoryEnum.AIR_HANDLING_WATER_SYSTEMS, name: EsmCategoryNamesEnum.AIR_HANDLING_WATER_SYSTEMS },
    { id: EsmCategoryEnum.CUSTOM, name: EsmCategoryNamesEnum.CUSTOM }
];

export enum FrequencyEnum {
    Monthly = 20,
    EveryThreeMonths = 30,
    EverySixMonths = 40,
    Annual = 50
}

export enum FrequencyEnumNames {
    Monthly = 'Monthly',
    EveryThreeMonths = 'Three monthly',
    EverySixMonths = 'Six monthly',
    Annual = 'Yearly'
}

export const frequencyOptions: { id: FrequencyEnum, name: FrequencyEnumNames }[] = [
    { id: FrequencyEnum.Monthly, name: FrequencyEnumNames.Monthly },
    { id: FrequencyEnum.EveryThreeMonths, name: FrequencyEnumNames.EveryThreeMonths },
    { id: FrequencyEnum.EverySixMonths, name: FrequencyEnumNames.EverySixMonths },
    { id: FrequencyEnum.Annual, name: FrequencyEnumNames.Annual }
];