@if(form) {
<form [formGroup]="form">
    <div class="header">
        <div class="file-name">File Name *</div>
        <div class="type">Type</div>
        <div class="tags">Tags</div>
    </div>

    <hr class="qpx-hr-line" />

    <div class="content"
         *ngFor="let fileCtrlGroup of $any(filesCtrl?.controls); let i = index">

        <div class="file-name">
            <mat-form-field ngDefaultControl
                            class="w-100">
                <input matInput
                       data-testid="name"
                       maxlength="200"
                       [formControl]="fileCtrlGroup.controls['name']">

                @if(fileCtrlGroup.controls['name'].errors?.['required']) {
                    <mat-error>Name is required</mat-error>
                } @else {
                    @if(fileCtrlGroup.controls['name'].errors?.['maxlength']) {
                        <mat-error>Max length is 200 characters</mat-error>
                    }
                }
            </mat-form-field>
        </div>

        <div data-testid="type" class="type">
            <mat-form-field ngDefaultControl
                            
                            class="w-100">
                <mat-select panelClass="esm-select-category"
                            placeholder="Please select"
                            [formControl]="fileCtrlGroup.controls['type']">
                    <mat-option [value]="option"
                            data-testid="type-option"
                                *ngFor="let option of documentTypesOptions">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="tags">
            <qpx-tags (tagSelectionChange)="onTagSelectionChange()"
                      [selected]="fileCtrlGroup.controls['tags'].value"></qpx-tags>
        </div>

    </div>
</form>
}