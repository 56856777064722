import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BuildingStatus } from '../models';

// used to manage the state of the advanced building search screen
@Injectable({
  providedIn: 'root',
})
export class BuildingStatusService {
  private buildingStatusSubject: BehaviorSubject<BuildingStatus> = new BehaviorSubject<BuildingStatus>(BuildingStatus.UNDERCONSTRUCTION);

  buildingStatus$: Observable<BuildingStatus> = this.buildingStatusSubject.asObservable();

  updateBuildingStatus(buldingStatus: BuildingStatus): void {
    this.buildingStatusSubject.next(buldingStatus);
  }
}
