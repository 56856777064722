import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';
import { APP_CONFIG_TOKEN, AppConfigModel } from '../models/app-config.model';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

export type AccountType = AccountInfo | null;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentAccountSubject: BehaviorSubject<AccountType>;
  private isAuthenticating = false;

  currentAccount$: Observable<AccountType>;
  destroying$: Subject<void>;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_CONFIG_TOKEN) private appConfig: AppConfigModel,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.currentAccountSubject = new BehaviorSubject<AccountType>(null);
    this.currentAccount$ = this.currentAccountSubject.asObservable();
    this.destroying$ = new Subject<void>();
    this.initialize();
  }

  private initialize() {
    this.setCurrentAccount();

    this.authService.instance.enableAccountStorageEvents();

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.destroying$)
      )
      .subscribe((result: any) => {
        const payload = result.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken.acr === this.appConfig.b2cPolicies.signUpSignIn.name ||
          idtoken.tfp === this.appConfig.b2cPolicies.signUpSignIn.name
        ) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        /**
           If a user has just reset their password, force them log out as we need to hit the API connector to 
           get the users permissions from their oid token (workaround as adb2c api connector does not support this).
         */
        if(result.payload.idTokenClaims.isForgotPassword) {
            this.logout();
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === this.appConfig.b2cPolicies.editProfile.name ||
          idtoken.tfp === this.appConfig.b2cPolicies.editProfile.name
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  this.appConfig.b2cPolicies.signUpSignIn.name ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    this.appConfig.b2cPolicies.signUpSignIn.name)
            );

          const signUpSignInFlowRequest: SsoSilentRequest = {
            authority: this.appConfig.b2cPolicies.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.authService.ssoSilent(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: this.appConfig.b2cPolicies.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }
      });
  }

  private setCurrentAccount() {
    const currentAccount = this.authService.instance.getActiveAccount();
    this.currentAccountSubject.next(currentAccount);
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    // Check if there's already an interaction in progress
    if (this.authService.instance.getAllAccounts().length > 0) {
      return; // User is already logged in
    }

    if (this.isAuthenticating) {
      return; // Prevent multiple authentication attempts
    }

    this.isAuthenticating = true;

    try {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService
            .loginPopup({
              ...this.msalGuardConfig.authRequest,
              ...userFlowRequest,
            } as PopupRequest)
            .subscribe({
              next: (response: AuthenticationResult) => {
                this.authService.instance.setActiveAccount(response.account);
                this.isAuthenticating = false;
              },
              error: () => {
                this.isAuthenticating = false;
              }
            });
        } else {
          this.authService
            .loginPopup(userFlowRequest)
            .subscribe({
              next: (response: AuthenticationResult) => {
                this.authService.instance.setActiveAccount(response.account);
                this.isAuthenticating = false;
              },
              error: () => {
                this.isAuthenticating = false;
              }
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as RedirectRequest);
        } else {
          this.authService.loginRedirect(userFlowRequest);
        }
      }
    } catch (error) {
      this.isAuthenticating = false;
      throw error; // Let the GlobalErrorHandler handle it
    }
  }

  logout() {
    if (this.isAuthenticating) {
      return;
    }

    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (activeAccount) {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }
}
