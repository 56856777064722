import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    standalone: true,
    selector: 'qpx-loader',
    template: `<mat-spinner [color]="color" class="qpx-loader" [ngClass]="inputClasses"></mat-spinner>`,
    styles: [`
    :host {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }`],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class LoaderComponent {
    @Input() inputClasses!: string[];
    @Input() color: string = 'primary';
}
