import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import { IRolesWithPermission } from '../models/typings/roles-with-permissions.interface';

@Injectable({
    providedIn: 'root'
})
export class PermissionApiService extends ApiServiceBase {
    private apiUserBase = `${this.appConfig.apiBase}/api/permissions`;

    /**
     * @returns a list of all roles and their associated permissions
     */
    getRolesWithPermissions(): Observable<IRolesWithPermission[]> {
        const url = `${this.apiUserBase}/all`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getAllPermissions() {
        const url = `${this.apiUserBase}/categories`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateRole(role: IRolesWithPermission) {
        const url = `${this.apiUserBase}/role`;
        return this.http.put<any>(url, role).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    addRole(role: IRolesWithPermission) {
        const url = `${this.apiUserBase}/role`;
        return this.http.post<any>(url, role).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
