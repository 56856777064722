@if(items && items.length) {
    @if(type === 'label') {
        <div class="labels">
            @if(showAll()) {
                @for (item of items; track item; let index = $index) {
                    <div>{{item}}</div>
                    @if(index < items.length-1) {
                        <span>,&nbsp;</span>
                    }
                }
            } @else {
                @for (item of items.slice(0, 2); track item; let index = $index) {
                    <div>{{item}}</div>
                    @if(index < items.length-1) {
                        <span>,&nbsp;</span>
                    }
                }
                @if(items.length > 2) {
                    <span class="more" (click)="onShowMoreClick()">
                        + {{items.length-2}} more
                    </span>
                }
            }
        </div>
    }

    @if(type === 'tag') {
        <div class="tags">
            @if(showAll()) {
                @for (item of items; track item) {
                    <div class="tag-item">{{item}}</div>
                }
            } @else {
                @for (item of items.slice(0, 2); track item) {
                    <div class="tag-item">{{item}}</div>
                }
                @if(items.length > 2) {
                    <span class="more" (click)="onShowMoreClick()">
                        + {{items.length-2}} more
                    </span>
                }
            }
        </div>
    }
}