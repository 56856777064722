// validator.service.ts
import { Injectable } from '@angular/core';
import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';


@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    public checkNumberingValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const userInput = control.value;
            const forbidden = userInput != null && userInput !== 'N/A' && userInput % 1 !== 0;
            return forbidden ? { 'checkNumbering': { value: control.value } } : null;
        };
      }
}
