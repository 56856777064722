import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    rangeToPercent(value: number, min: number, max: number): number {
        return ((value - min) / (max - min));
    }
}

export function transformAddress(address: string): string {
    if (!address) { return ''; }

    // Split the address into main parts based on spaces
    const parts = address.split(' ');

    // Extract and format the postcode and state
    const postcode = parts.pop();
    const state = parts.pop();

    // Join the remaining parts to handle the street address and city
    const remainingParts = parts.join(' ').split(',');

    // Process the street address part
    const streetAddress = remainingParts[0].split(' ');
    const streetNumber = streetAddress[0];
    const streetName = streetAddress.slice(1).map(capitalize).join(' ');

    // Process the city part
    const city = remainingParts[1].trim().split(' ').map(capitalize).join(' ');

    // Combine all parts into the final formatted address
    const formattedAddress = `${streetNumber} ${streetName}, ${city}, ${state} ${postcode}`;

    return formattedAddress;
}

// Helper function to capitalize the first letter of a word
function capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
