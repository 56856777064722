import { Injectable } from "@angular/core";
import { Observable, map, catchError } from "rxjs";
import { ApiServiceBase } from "../api-service-base";

@Injectable({
    providedIn: 'root'
})
export class AddressApiService extends ApiServiceBase {
    private apiAccessBase = `${this.appConfig.addressApiBase}/api`;

    getUnitsCountByAddressIdentifierAsync(addressId: string): Observable<number> {
        const url = `${this.apiAccessBase}/addresses/${addressId}/units-count`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
