<ng-container>
    <h2 mat-dialog-title>
        <mat-icon (click)="onCancel()">close</mat-icon>
    </h2>
</ng-container>

<mat-dialog-content>
    <div class="confirm-dialog-title">{{data.title}}</div>
    <div>{{data.description}}</div>

    <div *ngIf="data.items?.length"
         class="items-list">
        <div class="item"
             *ngFor="let item of data.items">- {{item}}</div>
    </div>

    <div>{{data.question}}</div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="cancel-button-wrapper">
        <button mat-stroked-button
                (click)="onCancel()">
            <mat-icon class="qpx-icon-sm">
                close
            </mat-icon>
            {{data.cancelButtonText}}
        </button>
    </div>
    <button mat-flat-button
            color="accent"
            mat-dialog-close="confirm"
            cdkFocusInitial>
            {{data.confirmButtonText}}
    </button>
</mat-dialog-actions>