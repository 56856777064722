import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Input,
    OnInit,
    inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule
} from '@angular/forms';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IOrganisationSearch, OrganizationApiService } from '@quipex/shared/data';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    of,
    switchMap
} from 'rxjs';

@Component({
    selector: 'qpx-company-name-dropdown',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule
    ],
    templateUrl: './company-name-dropdown.component.html',
    styleUrls: ['./company-name-dropdown.component.scss'],
})
export class CompanyNameDropDownComponent implements OnInit {
    @Input({ required: true }) form!: FormGroup;
    @Input() buildingId = '';

    filteredCompanyNames$ = new BehaviorSubject<IOrganisationSearch[]>([]);

    private readonly _minNumberOfCharsToTrigerSearch = 3;

    get companyNameSearchCtrl(): FormControl {
        return this.form.controls['companyNameSearch'] as FormControl;
    }

    private readonly destroyRef = inject(DestroyRef);
    private readonly organizationService = inject(OrganizationApiService);

    ngOnInit(): void {
        this.configurecompanyNameSearch();
    }

    displayFn(item: { id: string, companyName: string }): string {
        return item?.companyName ?? '';
    }

    onCompanyNameSelected(event: MatAutocompleteSelectedEvent): void {
        this.form.controls["companyNameSearch"].patchValue(event.option.value);
        this.filteredCompanyNames$.next([]);
    }

    private configurecompanyNameSearch(): void {
        this.companyNameSearchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                filter((value) => {
                    return (
                        typeof value === 'string' &&
                        value.trim() !== '' &&
                        value.length >= this._minNumberOfCharsToTrigerSearch
                    );
                }),
                switchMap((value) => {
                    return this.organizationService.getOrganizationsNames(value)
                        .pipe(
                            catchError((err) => {
                                return of(null);
                            })
                        );
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                if (!response?.length) {
                    response = [];
                }
                this.filteredCompanyNames$.next(response);
            });
    }

    private restoreCompanyName(companyName: any): void {
        this.filteredCompanyNames$.next([companyName]);
        this.companyNameSearchCtrl.setValue(companyName);
    }
}

