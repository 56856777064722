import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
    OrganizationType,
    User,
    UserStore
} from '@quipex/shared/data';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasRole]',
    standalone: true
})
export class HasRoleDirective {
    private currentUser!: User;
    private roles!: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userStore: UserStore
    ) { }

    @Input()
    set hasRole(val: string[]) {
        this.userStore.select("me")
            .subscribe(user => {
                if (user.role != null) {
                    this.roles = val;
                    this.currentUser = user;
                    this.updateView();
                }

            });
    }

    private updateView(): void {
        this.viewContainer?.clear();
        if (this.checkRole()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private checkRole(): boolean {
        if (!this.currentUser?.role) {
            return false;
        }

        // If the user is Quipex Admin, they have access with no limitation
        if (this.currentUser.orgCategory === OrganizationType.ADMIN) {
            return true;
        }

        const currentUserRole = this.currentUser.role.toLowerCase();

        // Check if all roles are negative roles (starting with !)
        const allNegativeRoles = this.roles.every(role => role.startsWith('!'));
        
        if (allNegativeRoles) {
            // For negative roles, we want to ensure the user doesn't have ANY of the roles
            return this.roles.every(role => 
                !currentUserRole.includes(role.toLowerCase().substring(1))
            );
        }

        // Original logic for positive roles and mixed cases
        return this.roles.some((role) => {
            // Negation check (roles starting with !)
            if (role.startsWith('!')) {
                return !currentUserRole.includes(role.toLowerCase().substring(1));
            }
            
            // Wildcard check (roles starting with *)
            if (role.startsWith('*')) {
                return currentUserRole.includes(role.toLowerCase().substring(1));
            }
            
            // Exact match check
            return currentUserRole === role.toLowerCase();
        });
    }
}
