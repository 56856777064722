import { Component } from '@angular/core';

@Component({
  selector: 'qpx-loading-screen',
  standalone: true,
  template: `
    <div class="loader-container">
      <div class="loader-content">
        <img
          src="./assets/gif/document_loading.gif"
          alt="Loading..."
          width="136"
          height="102"
        />
        <h2>Doing smart things</h2>
        <p>
          Please wait a moment while we scan your<br>documents for key building
          information
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .loader-content {
        text-align: center;
      }
      h2 {
        margin-top: 1rem;
        font-weight: bold;
        color: var(--color-primary);
      }
      p {
        color: var(--color-primary-light);
      }
    `,
  ],
})
export class DocumentLoaderComponent {
}
