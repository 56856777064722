import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import {
    IAddUnitOwnerPostModel,
    IBuildingNamesResponse,
    IBuildingUnitNamesResponse,
    IUnitOwnerDetailsResponse, IUnitOwnerResponse, IUpdateUnitOwnerPostModel
} from '../models/typings/unit-owners.interface';
import {
    IAddTemplatePostModel, ITemplateResponse,
    IUpdateTemplatePostModel
} from '../models/typings/units-owner.interface';

@Injectable({
    providedIn: 'root',
})
export class UnitOwnerApiService extends ApiServiceBase {
    private _apiUnitOwnersBase = `${this.appConfig.apiBase}/api/unitowners`;

    getUnitOwners(payload: any): Observable<IUnitOwnerResponse> {
        const url = `${this._apiUnitOwnersBase}/all`;
        return this.http.post<any>(url, payload)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getUnitOwnerDetails(id: any): Observable<IUnitOwnerDetailsResponse> {
        const url = `${this._apiUnitOwnersBase}/${id}`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingNames(): Observable<IBuildingNamesResponse[]> {
        const url = `${this._apiUnitOwnersBase}/buildings-names`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingsWithUnits(): Observable<IBuildingUnitNamesResponse[]> {
        const url = `${this._apiUnitOwnersBase}/buildings-units-names`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingUnitNames(buildingId: string): Observable<IBuildingUnitNamesResponse[]> {
        const url = `${this._apiUnitOwnersBase}/${buildingId}/units-names`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addUnitOwner(postBody: IAddUnitOwnerPostModel): Observable<true> {
        const url = `${this._apiUnitOwnersBase}`;
        return this.http.post<any>(url, postBody)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateUnitOwner(postBody: IUpdateUnitOwnerPostModel): Observable<true> {
        const url = `${this._apiUnitOwnersBase}`;
        return this.http.put<any>(url, postBody)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getTemplates(buildingId: string): Observable<ITemplateResponse> {
        const url = `${this._apiUnitOwnersBase}/${buildingId}/templates`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addTemplate(model: IAddTemplatePostModel): Observable<any> {
        const url = `${this._apiUnitOwnersBase}/template`;
        return this.http.post<any>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateTemplate(model: IUpdateTemplatePostModel): Observable<any> {
        const url = `${this._apiUnitOwnersBase}/template`;
        return this.http.put<any>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    deleteTemplate(templateId: any): Observable<any> {
        const url = `${this._apiUnitOwnersBase}/${templateId}/templates`;
        return this.http.delete<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

} 
