import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnDestroy,
    OnInit,
    output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    EsmApiService,
    EsmCategoryEnum,
    esmCategoryyOptions,
    IGetESMDataPointsByCategoryResponse
} from '@quipex/shared/data';
import {
    ApexChart,
    ApexNonAxisChartSeries, ChartComponent, NgApexchartsModule
} from 'ng-apexcharts';
import { LoaderComponent } from '../loader.component';

export type DonutChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: any;
    colors: any;
    plotOptions: any;
    dataLabels: any;
    tooltip: any;
    legend: any;
    noData: any;
};

@Component({
    selector: 'qpx-donut-chart',
    standalone: true,
    imports: [
        CommonModule,
        NgApexchartsModule,
        MatIconModule,
        LoaderComponent
    ],
    templateUrl: './esm-donut-chart-widget.component.html',
    styleUrls: ['./esm-donut-chart-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements OnInit, OnDestroy {
    @ViewChildren(ChartComponent) chartComponents!: QueryList<ChartComponent>;

    otherInspectionsChange = output<boolean>();

    chartOptions: Partial<DonutChartOptions> | any;
    charts: { categoryName: string; chartOptions: Partial<DonutChartOptions | any> | null }[] = [];

    isEmptyDataSet!: boolean;

    labels = ['Compliant', 'Not compliant'];
    isLoaded = false;

    private readonly cdr = inject(ChangeDetectorRef);
    private readonly esmService = inject(EsmApiService);

    ngOnInit() {
        this.getESMDataPointsByCategory();
    }

    ngOnDestroy(): void {
        this.chartComponents.forEach((chart) => {
            chart.destroy();
        });
    }

    private getESMDataPointsByCategory(): void {
        this.esmService.getESMDataPointsByCategory()
            .subscribe({
                next: (response: IGetESMDataPointsByCategoryResponse[]) => {
                    const sortedResponse = this.sortResponse(response);
                    sortedResponse.forEach((item) => {

                        const totalCount = item.compliantCount + item.nonCompliantCount;
                        let chartOptions = null;

                        if (totalCount) {
                            chartOptions = this.generateChart([item.compliantCount, item.nonCompliantCount],
                                this.getPercentage(item.compliantCount, item.compliantCount + item.nonCompliantCount));
                        }

                        this.charts.push({
                            categoryName: esmCategoryyOptions.find((cat) => cat.id === item.esmCategory)?.name ?? '',
                            chartOptions: chartOptions
                        });

                        if (item.esmCategory === EsmCategoryEnum.CUSTOM) {
                            this.otherInspectionsChange.emit(true);
                        }
                    });
                    this.isLoaded = true;
                    this.cdr.markForCheck();
                }
            });
    }

    private generateChart(series: number[] = [], percentage: number): Partial<DonutChartOptions> {
        return {
            plotOptions: {
                pie: {
                    donut: {
                        size: '85%',
                        labels: {
                            show: true,
                            total: {
                                label: `${percentage}%`,
                                show: true,
                                showAlways: true,
                                fontSize: '32px',
                                fontWeight: 'bold',
                                color: '#103441',
                                formatter: () => 'Compliant'
                            }
                        }
                    },
                    customScale: 0.9,
                },
            },
            series: series,
            chart: {
                animations: {
                    enabled: false,
                },
                type: 'donut',
                width: '90%',
                // chartData: []
            },
            colors: ['#02add7', '#de8013'],
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
            },
            legend: {
                formatter: function (
                    label: string,
                    opts: {
                        w: { globals: { series: { [x: string]: string } } };
                        seriesIndex: string | number;
                    }
                ) {
                    return label + ' - ' + opts.w.globals.series[opts.seriesIndex];
                },
                horizontalAlign: "center",
                position: "right",
                floating: false,
                show: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                offsetY: -12,
                labels: {},
            }
        };
    }

    private sortResponse(response: IGetESMDataPointsByCategoryResponse[]): IGetESMDataPointsByCategoryResponse[] {
        const fireItem = response.find((item) => item.esmCategory === EsmCategoryEnum.FIRE_PROTECTION_SYSTEMS_EQUIPMENT);
        const airItem = response.find((item) => item.esmCategory === EsmCategoryEnum.AIR_HANDLING_WATER_SYSTEMS);
        const emergencyItem = response.find((item) => item.esmCategory === EsmCategoryEnum.EMERGENCY_LIGHTING_EXIT_SIGNS);
        const customItem = response.find((item) => item.esmCategory === EsmCategoryEnum.CUSTOM);

        const sortedResponse = [] as any;

        if (fireItem) {
            sortedResponse.push(fireItem);
        }
        if (airItem) {
            sortedResponse.push(airItem);
        }
        if (emergencyItem) {
            sortedResponse.push(emergencyItem);
        }
        if (customItem) {
            sortedResponse.push(customItem);
        }

        return sortedResponse;
    }

    private getPercentage(partialValue: number, totalValue: number): number {
        if (!partialValue || !totalValue) { return 0; }

        return Math.floor((100 * partialValue) / totalValue);
    }

}
