import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Observable, from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsLoaderService {
    private _loadedMap$!: Observable<any>;
    private _loadedMarker$!: Observable<any>;

    get loadedMap$(): Observable<any> {
        if (!this._loadedMap$) {
            this._loadedMap$ = this.loadMap();
        }

        return this._loadedMap$;
    }

    get loadedMarker$(): Observable<any> {
        if (!this._loadedMarker$) {
            this._loadedMarker$ = this.loadMarker();
        }

        return this._loadedMarker$;
    }

    private loadMap(): Observable<any> {
        const loader = new Loader({
            apiKey: 'AIzaSyDUFgMZV4DdS3_9rKhTVB43bkZlK06m9Z0',
            version: 'weekly',
            libraries: ['places']
        });

        return from(loader.importLibrary('maps'));
    }

    private loadMarker(): Observable<any> {
        return from(google.maps.importLibrary('marker'));
    }

}
