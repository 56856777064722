<div class="flex justify-between qpx-card-title">
    <span>Building Manual</span>
    <a class="flex align-center qpx-nav-link"
       [routerLink]="['/buildings', buildingGuid, 'site-details']"
       routerLinkActive="active">
        <ng-container *ngIf="canUpdate">
            <span class="qpx-nav-link-item">Update</span>
            <mat-icon class="qpx-nav-link-item material-symbols-outlined">
                chevron_right
            </mat-icon>
        </ng-container>
    </a>
</div>

<div class="flex wrap align-center justify-between statistics">
    <span class="qpx-percentage">
        {{buildingManualPercentage}}
        <span class="qpx-percentage-sign">%</span>
        <span class="qpx-percentage-complete">Complete</span>
    </span>

    <div class="progress-wrapper">
        <mat-label for="mat-progress-bar">{{missingKeyItemsNo}} items missing</mat-label>
        <mat-progress-bar id="mat-progress-bar"
                          mode="determinate"
                          value="{{progressValue}}">
        </mat-progress-bar>
    </div>
</div>