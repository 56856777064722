import { CommonModule } from '@angular/common';

import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { BuildingApiService, SearchService, User, UserStore } from '@quipex/shared/data';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LoaderComponent } from '../loader.component';
import {  IBuildingResponse } from 'modules/shared/data/src/lib/models/typings/building-list-response.interface';

@Component({
    selector: 'qpx-building-search',
    templateUrl: './building-search.component.html',
    styleUrls: ['./building-search.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatIconModule,
        MatListModule,
        MatInputModule,
        MatAutocompleteModule,
        LoaderComponent
    ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'building-search-autocomplete' }
        }
    ]
})
export class BuildingSearchComponent implements OnInit, OnDestroy {
    isDropdownOpen!: boolean;
    isLoading = false;
    form!: FormGroup;
    filteredBuildingNames$ = new BehaviorSubject<IBuildingResponse[]>([]);

    @ViewChild('searchInputEl') searchInputEl!: ElementRef;
    private _currentUsersRole: any;

    get buildingNameSearchCtrl(): FormControl {
        return this.form.controls['buildingNameSearch'] as FormControl;
    }

    private destroy$ = new Subject<void>(); // Subject to signal component destruction

    constructor(
        private buildingService: BuildingApiService,
        private searchService: SearchService,
        private formBuilder: FormBuilder,
        private router: Router,
        private elRef: ElementRef,
        private userStore: UserStore
    ) { }

    ngOnInit() {
        this.configureForm();
        this.configureBuildingNameSearch();
        this.getUserInfo();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // Close the dropdown when clicking outside
    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {
        const eventTarget = event?.target as any;
        if (eventTarget && eventTarget?.id?.indexOf('mat-autocomplete') !== -1) {
            return;
        }
        if (!this.elRef.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
            this.resetSearchCriteria();
        }
    }

    toggleDropdown() {
        // Toggle the dropdown visibility
        this.isDropdownOpen = !this.isDropdownOpen;

        if (this.isDropdownOpen) {
            setTimeout(() => {
                this.searchInputEl?.nativeElement.focus();
            });
        } else {
            this.resetSearchCriteria();
        }
    }

    clearSearch($event: Event): void {
        $event.stopPropagation(); // Prevents the click event from reaching the parent (closing dropdown)
        this.resetSearchCriteria();
    }

    onBuildingNameSelected(event: MatAutocompleteSelectedEvent): void {
        this.navigateToDetails(event.option.value.buildingGuid);
        this.buildingNameSearchCtrl.setValue('');
        this.searchService.closeSearch();
    }

    navigateToDetails(id: string): void {
        if (this._currentUsersRole.toLowerCase().indexOf('builder') > -1) { // user has a builder role
            this.router.navigate(['/buildings', id, 'site-details']);
        }
        else if (this._currentUsersRole.toLowerCase().indexOf('maintenance') > -1) { // user has a maintenance contractor role
            this.router.navigate(['/buildings', id, 'maintenance']);
        }
        else {
            this.router.navigate(['/buildings', id]);
        }
        this.isDropdownOpen = false;
    }

    private configureForm(): void {
        if (!this.form) {
            this.form = this.formBuilder.group({
                buildingNameSearch: [''],
            });
        }
    }

    private configureBuildingNameSearch(): void {
        this.buildingNameSearchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                filter((value) => {
                    if (typeof value === 'string' && value.trim() === '') {
                        this.filteredBuildingNames$.next([]);
                    }
                    return (
                        typeof value === 'string' &&
                        value.trim() !== ''
                    );
                }),
                switchMap((value) => {
                    const reqBody: any = {
                        pageNo: 1,
                        pageSize: 10,
                        searchTerm: value,
                        selectedAppType: 1,
                        sortField: 10, //BUILDING
                        sortOrder: 1, // ASCENDING
                    };
                    this.isLoading = true;
                    return this.buildingService.getBuildings(reqBody)
                        .pipe(
                            map((response) => response),
                            catchError((err) => {
                                this.isLoading = false;
                                return of(null);
                            })
                        );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((response) => {
                if (response?.buildings) {
                    this.filteredBuildingNames$.next(response?.buildings);
                    this.isLoading = false;
                }
            });
    }

    private resetSearchCriteria(): void {
        this.buildingNameSearchCtrl.setValue('');
        this.filteredBuildingNames$.next([]);
    }

    private getUserInfo(): void {
        this.userStore.select('me')
            .pipe(takeUntil(this.destroy$))
            .subscribe((user: User) => {
                this._currentUsersRole = user.role
            });
    }

}
