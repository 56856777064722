<form [formGroup]="form">
    <mat-label> Company name *</mat-label>
    <mat-form-field class="w-100">
        <input matInput
               #companyNameSearchEl
               type="text"
               name="companyName"
               data-testid="companyNameLink"
               spellcheck="false"
               autocomplete="off"
               maxlength="50"
               required
               [matAutocomplete]="companyNameSearch"
               (focus)="filteredCompanyNames$.next([]);"
               [formControl]="companyNameSearchCtrl">

        <mat-autocomplete autoActiveFirstOption
                          #companyNameSearch="matAutocomplete"
                          [displayWith]="displayFn"
                          (optionSelected)="onCompanyNameSelected($event)">
            <ng-container *ngIf="filteredCompanyNames$.value.length > 0">
                <ng-container *ngFor="let result of filteredCompanyNames$ | async as searchResult">
                    <mat-option [value]="result"
                                [disabled]="result.id === '0'">

                        <span class="display-company-name">
                            {{ result.id === "0" ? 'No Search Results...' : result.companyName}}
                        </span>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>

        @if(form.controls['companyNameSearch'].errors?.['required']) {
            <mat-error>Company name is required</mat-error>
        }
    </mat-form-field>
</form>