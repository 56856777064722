import { Injectable } from '@angular/core';
import {
    BlobServiceClient,
    BlobUploadCommonResponse,
} from '@azure/storage-blob';
import { saveAs } from 'file-saver';
import { Observable, catchError, map } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import {
    BuildingDocument,
    BuildingDocuments,
    ClassifyDocumentResponse,
    DocDto,
    DocumentDetails,
    DocumentTypeEnum,
    IDuplicateDocRequest,
    IGetInsightsResponse,
    IOCDetailsExtraction
} from '../models';

@Injectable({
    providedIn: 'root',
})
export class DocumentApiService extends ApiServiceBase {
    private apiDocumentService = `${this.appConfig.apiBase}/api/documents`;
    files: any;

    setfile(file: any) {
        this.files = file;
    }
    getfile() {
        return this.files;
    }

    uploadFile(
        file: File,
        blobSasUrl: string,
        folderPath: string,
        fileName: string
    ): Promise<BlobUploadCommonResponse> {
        const blobServiceClient = new BlobServiceClient(blobSasUrl);
        const containerClient = blobServiceClient.getContainerClient(folderPath);
        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        return blockBlobClient.uploadData(file);
    }

    // File Specific
    getDocumentUrlsToDownload(documentIds?: Array<string>, documentId?: number) {
        let url = `${this.apiDocumentService}/download/${documentId?.toString() ?? ''}`;
        if (documentIds != undefined && documentIds?.length > 1) {
            url = `${this.apiDocumentService}/download-zip`;
            return this.http.post(url, documentIds, { responseType: 'blob' }).pipe(
                map((response: Blob) => {
                    const downloadUrl = URL.createObjectURL(response);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = 'documents.zip'; // Set the desired file name
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(downloadUrl);
                }),
                catchError(this.handleError)
            );
        }
        return this.http.post<any>(url, documentIds).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    downloadDoc(url: string, documentName: string) {
        if (!url.length) return;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, documentName);
            })
        .catch(error => console.error('Error downloading file:', error));
    }


    // Document Specific

    getAllTags(searchTerm: string): Observable<any[]> {
        const url = `${this.apiDocumentService}/tags?searchterm=${searchTerm}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getDocumentDetailsById(documentGuid: string) {
        const url = `${this.apiDocumentService}/${documentGuid}/details`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateDocumentDetails(documentDetails: DocumentDetails): Observable<any> {
        const data = {
            name: documentDetails.name,
            documentGuid: documentDetails.documentGuid,
            documentType: documentDetails.documentType,
            tags: documentDetails.tags,
            buildingId: documentDetails.buildingId,
            userId: documentDetails.userId
        };

        const url = `${this.apiDocumentService}/${documentDetails.documentGuid}/edit`;
        return this.http.post<any>(url, data).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    // getDocumentVersionDetails(documentGuid: string) {
    //   const req = this.apiConfigService.getApiConfig(
    //     APICONFIG.VERSION_HISTORY
    //   );
    //   req.requestUri = req.requestUri.replace('{$documentGuid}', documentGuid);
    //   return this.http.post<any>(req).pipe(
    //     map((result: any) => {
    //       return result.data;
    //     })
    //   );
    // }

    fetchDocuments(postBody: any) {
        const url = `${this.apiDocumentService}/all`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    deleteDocument(postBody: any) {
        const url = `${this.apiDocumentService}/${postBody.docGuid}/delete`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    classifyDocuments(files: File[]): Observable<ClassifyDocumentResponse[]> {
        const formData = new FormData();

        files.forEach((file: File) => {
            formData.append('files', file);
        });

        const url = `${this.apiDocumentService}/classify`;
        return this.http.post<any>(url, formData).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    extractOCDetails(file: File, buildingId: string | null, addressToMatch: string | null): Observable<IOCDetailsExtraction> {
        const url = `${this.apiDocumentService}/oc-extraction`;

        const formData = new FormData();
        formData.append('file', file);
        if (buildingId) {
            formData.append('buildingId', buildingId);
        }
        if (addressToMatch) {
            formData.append('addressToMatch', addressToMatch);
        }
        return this.http.post<IOCDetailsExtraction>(url, formData);
    }

    getInsights(searchTerm: string): Observable<IGetInsightsResponse> {
        const url = `${this.apiDocumentService}/ask`;
        return this.http.post<any>(url, { searchTerm })
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    async prepareDocumentsForUpload(files: any, buildingId: string, currentUserId: number): Promise<BuildingDocuments> {
        const buildingDocuments = await Promise.all(
            files.map(async (document: DocDto) => {
                return {
                    uploadedFile: document.file,
                    documentType: document.documentType,
                    tags: document.autoTag ?? []
                } as BuildingDocument;
            })
        );
        const documents = new BuildingDocuments();
        documents.buildingId = buildingId;
        documents.buildingDocuments = buildingDocuments;
        documents.userId = currentUserId;
        return documents;
    }

    uploadDocument(postBody: BuildingDocuments) {
        const formData = new FormData();

        const url = `${this.apiDocumentService}`;

        formData.append('buildingId', postBody.buildingId);
        formData.append('userId', postBody.userId.toString());
        // Use the spread operator to append all files at once
        postBody.buildingDocuments.forEach((file, index) => {
            formData.append(`buildingDocuments[${index}].uploadedFile`, file.uploadedFile);
            if (file.documentType) {
                formData.append(`buildingDocuments[${index}].documentType`, file.documentType.toString());
            } else {
                formData.append(`buildingDocuments[${index}].documentType`, DocumentTypeEnum.Other.toString());
            }

            if (!file.tags?.length) {
                formData.append(`buildingDocuments[${index}].tags`, '');
            } else {
                file.tags.forEach((tag, indexTag) => {
                    formData.append(`buildingDocuments[${index}].tags[${indexTag}].TagKeyDocType`, tag.tagKeyDocType?.toString() ?? '');
                    formData.append(`buildingDocuments[${index}].tags[${indexTag}].tagGuid`, tag.tagGuid?.toString() ?? '');
                    formData.append(`buildingDocuments[${index}].tags[${indexTag}].tagName`, tag.tagName);
                });
            }
        });

        return this.http.post<any>(url, formData).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    checkDuplicateDocument(postBody: IDuplicateDocRequest): Observable<string[]> {
        const url = `${this.apiDocumentService}/check-duplicate`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
