import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import { Domain } from '../models/organization.model';
import { IAddOrganisationPostModel } from '../models/typings/add-organisation-post-model.interface';
import { IOrganisation } from '../models/typings/organisation-interface';
import { IOrganisationSearch } from '../models/typings/organisation-search.interface';

@Injectable({
    providedIn: 'root'
})
export class OrganizationApiService extends ApiServiceBase {
    private apiOrganizationBase = `${this.appConfig.apiBase}/api/organizations`;

    getAllOrganizationsPagination(postBody: any) {
        const url = `${this.apiOrganizationBase}/list`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getOrganizationsNames(searchTerm: string): Observable<IOrganisationSearch[]> {
        const url = `${this.apiOrganizationBase}/names/${searchTerm}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getOwnersNames(searchTerm: string): Observable<IOrganisationSearch[]> {
        const url = `${this.apiOrganizationBase}/owners/names/${searchTerm}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getAllOrganizations(): Observable<IOrganisation[]> {
        const url = `${this.apiOrganizationBase}/all`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getOrganization(organizationGuid: string) {
        const url = `${this.apiOrganizationBase}/${organizationGuid}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    addOrganization(postBody: IAddOrganisationPostModel) {
        const formData = new FormData();

        formData.append('name', postBody.name);
        formData.append('phone', postBody.phone ?? '');
        formData.append('email', postBody.email ?? '');
        formData.append('category', postBody.category.toString());

        formData.append('hasLogo', postBody.hasLogo.toString());
        formData.append('logo', postBody.logo ?? '');

        postBody.allowedDomains?.forEach((domain: Domain, index: number) => {
            formData.append(`allowedDomains[${index}].name`, domain.name);
        });

        const url = `${this.apiOrganizationBase}`;
        return this.http.post<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    editOrganization(postBody: IAddOrganisationPostModel) {
        const formData = new FormData();

        formData.append('orgGuid', postBody.orgGuid ?? '');
        formData.append('name', postBody.name);
        formData.append('phone', postBody.phone ?? '');
        formData.append('email', postBody.email ?? '');
        formData.append('category', postBody.category.toString());

        formData.append('hasLogo', postBody.hasLogo.toString());
        formData.append('logo', postBody.logo ?? '');

        postBody.allowedDomains?.forEach((domain: Domain, index: number) => {
            formData.append(`allowedDomains[${index}].name`, domain.name);
        });

        const url = `${this.apiOrganizationBase}/${postBody.orgGuid}/edit`;
        return this.http.put<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    deactivateOrganization(id: string) {
        const url = `${this.apiOrganizationBase}/${id}`;
        return this.http.delete<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    activateOrganization(id: string) {
        const url = `${this.apiOrganizationBase}/activate/${id}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
