<div class="document-modal">
    @if(isLoading()) {
        <qpx-loader></qpx-loader>
    }

    <h1 class="dialog-content-title">Edit details</h1>

    @if(form) {
        <form [formGroup]="form">
            <mat-label>Name</mat-label>
            <mat-form-field class="w-100">
                <input matInput
                    data-testid="name"
                    maxlength="200"
                    formControlName="name"/>

                @if(form.controls['name'].errors?.['required']) {
                    <mat-error>Name is required</mat-error>
                } @else {
                    @if(form.controls['name'].errors?.['maxlength']) {
                        <mat-error>Max length is 200 characters</mat-error>
                    }
                }
            </mat-form-field>

            <mat-label>Type</mat-label>
            <mat-form-field class="w-100">
                <mat-select #matSelect
                            placeholder="Please select"
                            formControlName="type">
                    <mat-option [value]="option"
                                *ngFor="let option of documentTypesOptions">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div>
                <mat-label title="Press enter after each tag">Tags</mat-label>
                <qpx-tags [selected]="form.controls['tags'].value"></qpx-tags>
            </div>
        </form>
    }

</div>