import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IGenericDialogData } from "./duplicates-modal.component";

@Injectable({
    providedIn: 'root',
})
export class DuplicatesModalService {
    dialogVisible$ = new Subject<boolean>();
    dialogDataChange$ = new BehaviorSubject<IGenericDialogData | null>(null);

    setDialogVisible(visible: boolean): void {
        this.dialogVisible$.next(visible);
    }

    updateDialogData(data: IGenericDialogData | null): void {
        this.dialogDataChange$.next(data);
    }
}