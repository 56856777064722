import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {
    User,
    UserStore
} from '@quipex/shared/data';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]',
    standalone: true
})
export class HasPermissionDirective implements OnInit {
    private currentUser!: User
    private permissions!: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userStore: UserStore
    ) { }

    ngOnInit() {
        this.userStore.select("me")
            .subscribe(user => {
                this.currentUser = user;
                this.updateView();
            });
    }

    @Input()
    set hasPermission(val: string[]) {
        this.permissions = val;
        this.updateView();
    }

    private updateView(): void {
        this.viewContainer.clear();
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private checkPermission() {
        if (this.currentUser && this.currentUser.permissions) {
            for (const checkPermission of this.permissions) {
                const permissionFound = this.currentUser.permissions
                    .filter(permission => permission.toUpperCase()
                        .includes(checkPermission.toUpperCase()));
                if (permissionFound.length != 0) return true;
            }
        }
        return false;
    }
}
