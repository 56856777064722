/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IAddressResponse, ISuggestAddressResponse } from './typings/geoscape/geoscape-models.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AddressSearchService {
    private GEOSCAPE_API_URL = 'https://api.psma.com.au/';
    private GEOSCAPE_API_KEY = environment.geoscapeApiKey;

    constructor(private _httpClient: HttpClient) {
    }

    suggestAddress(query: string): Observable<ISuggestAddressResponse[]> {
        const headers = new HttpHeaders().set('Authorization', `${this.GEOSCAPE_API_KEY}`);
        const params = new HttpParams().set('query', query);

        return this._httpClient.get(`${this.GEOSCAPE_API_URL}/v1/predictive/address`, { headers, params })
            .pipe(map((response: any) => {
                return response.suggest;
            }));
    }

    getAddressDetails(id: string): Observable<IAddressResponse> {
        const headers = new HttpHeaders().set('Authorization', `${this.GEOSCAPE_API_KEY}`);

        return this._httpClient.get(`${this.GEOSCAPE_API_URL}/v1/predictive/address/${id}`, { headers })
            .pipe(map((response: any) => {
                return response.address;
            }));
    }
}


