@if(isLoading()) {
    <qpx-loader></qpx-loader>
} @else {
    <div class="title">Understand your risks</div>

    @if(scoreData(); as scoreData){
        <mat-accordion>
            <mat-expansion-panel class="mat-expansion-panel esm-nested-panel blue-carret score-overview">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Building Integrity Score
                    </mat-panel-title>
                    <mat-panel-description>
                        @if(scoreData.integrityTotalScore) {
                            <span>-</span>
                        }
                        {{scoreData.integrityTotalScore}}
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-expansion-panel class="score-overview-nested">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="nested-title">
                            {{scoreData.integrityScores.esMsCountScore}} Overdue Checks
                        </mat-panel-title>
                        <mat-panel-description class="nested-description">
                            @if(scoreData.integrityScores.esMsTotalScore) {
                                <span>-</span>
                            }
                            {{scoreData.integrityScores.esMsTotalScore}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    @for(item of scoreData.integrityScores.esMsDetails; track item; let last = $last) {
                        <div class="score-record"
                            [ngClass]="{'last': last, 'link': canNavigateToMaintenance()}"
                            (click)="navigateToEsmTab(item.category, item.name)">
                            <div class="score-record__info">
                                <div class="category-name">{{item.categoryName}}</div>
                                <div>{{item.name}}</div>
                            </div>
                            <div class="score-record__score">-{{item.score}}</div>
                        </div>
                    }
                </mat-expansion-panel>

                <mat-expansion-panel class="score-overview-nested">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="nested-title">
                            {{scoreData.integrityScores.defectsCountScore}} Critical Defects
                        </mat-panel-title>
                        <mat-panel-description class="nested-description">
                            @if(scoreData.integrityScores.defectsTotalScore) {
                                <span>-</span>
                            }
                            {{scoreData.integrityScores.defectsTotalScore}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    @for(item of scoreData.integrityScores.defectsDetails; track item; let last = $last) {
                        <div class="score-record"
                            [ngClass]="{'last': last, 'link': canNavigateToMaintenance()}"
                            (click)="navigateToEsmTab(item.category, item.name)">
                            <div class="score-record__info">
                                <div class="category-name">{{item.categoryName}}</div>
                                <div [innerHTML]="item.descriptionFormatted"></div>
                            </div>
                            <div class="score-record__score">-{{item.score}}</div>
                        </div>
                    }
                </mat-expansion-panel>
            </mat-expansion-panel>


            <!-- Other risks -->
            <mat-expansion-panel class="mat-expansion-panel esm-nested-panel blue-carret score-overview">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Other Risks
                    </mat-panel-title>
                    <mat-panel-description>
                        @if(scoreData.otherRiskTotalScore) {
                            <span>-</span>
                        }
                        {{scoreData.otherRiskTotalScore}}
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-expansion-panel class="score-overview-nested">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="nested-title">
                            {{scoreData.otherRisks.esMsCountScore}} Overdue Checks
                        </mat-panel-title>
                        <mat-panel-description class="nested-description">
                            @if(scoreData.otherRisks.esMsTotalScore) {
                                <span>-</span>
                            }
                            {{scoreData.otherRisks.esMsTotalScore}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    @for(item of scoreData.otherRisks.esMsDetails; track item; let last = $last) {
                        <div class="score-record"
                            [ngClass]="{'last': last, 'link': canNavigateToMaintenance()}"
                            (click)="navigateToEsmTab(item.category, item.name)">
                            <div class="score-record__info">
                                <div class="category-name">{{item.categoryName}}</div>
                                <div>{{item.name}}</div>
                            </div>
                            <div class="score-record__score">-{{item.score}}</div>
                        </div>
                    }
                </mat-expansion-panel>

                <mat-expansion-panel class="score-overview-nested">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="nested-title">
                            {{scoreData.otherRisks.defectsCountScore}} Critical Defects
                        </mat-panel-title>
                        <mat-panel-description class="nested-description">
                            @if(scoreData.otherRisks.defectsTotalScore) {
                                <span>-</span>
                            }
                            {{scoreData.otherRisks.defectsTotalScore}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    @for(item of scoreData.otherRisks.defectsDetails; track item; let last = $last) {
                        <div class="score-record"
                            [ngClass]="{'last': last, 'link': canNavigateToMaintenance()}"
                            (click)="navigateToEsmTab(item.category, item.name)">
                            <div class="score-record__info">
                                <div class="category-name">{{item.categoryName}}</div>
                                <div [innerHTML]="item.descriptionFormatted"></div>
                            </div>
                            <div class="score-record__score">-{{item.score}}</div>
                        </div>
                    }
                </mat-expansion-panel>
            </mat-expansion-panel>
        </mat-accordion>
    }
}