import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
    DocumentApiService,
    FileService,
    IDuplicateDocRequest
} from '@quipex/shared/data';
import { DndDirective } from '@quipex/shared/directives';
import { LoaderComponent } from '../../loader.component';
import { DuplicatesModalService } from '../duplicates-modal/duplicates-modal.service';
import { DuplicatesModalComponent } from '../duplicates-modal/duplicates-modal.component';

@Component({
    selector: 'qpx-file-upload',
    standalone: true,
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        LoaderComponent,
        DuplicatesModalComponent,
        DndDirective
    ]
})
export class FileUploadComponent {
    @Input() buildingId!: string;
    @Input() shouldCheckForDuplicates = true;
    @Input() multiple = 'multiple';
    @Input() withDragAndDrop = true;
    @Input() buttonType = true;

    @Input() set isLoading(value: boolean) {
        this.uploadEnabled = !value;
    }

    @ViewChild('fileInput') fileInput!: ElementRef;

    fileListChange = output<File[]>();
    processingChange = output<boolean>();

    uploadEnabled = true;

    constructor(
        private matDialog: MatDialog,
        private documentService: DocumentApiService,
        private fileService: FileService,
        private duplicatesModalService: DuplicatesModalService
    ) {
        this.processingChange.emit(false);
    }

    openFileBrowser(): void {
        this.fileInput.nativeElement.click();
    }

    onFileInputClick(event: any): void {
        // needed for enabling same file upload
        event.target.value = null;
    }
    /**
     * on file drop handler
     */
    onFileDropped($event: any): void {
        if (!$event?.length) {
            return;
        }
        const files = [...Array.from($event)] as File[];

        this.uploadEnabled = false;
        this.processingChange.emit(true);

        if (this.fileService.validateFiles(files)) {
            if (this.shouldCheckForDuplicates) {
                this.checkDuplicateDocument(files);
            } else {
                this.fileListChange.emit(files);
                this.uploadEnabled = true;
            }
        } else {
            this.uploadEnabled = true;
            this.processingChange.emit(false);
        }
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler($event: any) {
        const fileList: FileList = $event.target.files;
        if (!fileList?.length) {
            return;
        }
        const files = [...Array.from(fileList)];

        this.uploadEnabled = false;
        this.processingChange.emit(true);

        if (this.fileService.validateFiles(files)) {
            if (this.shouldCheckForDuplicates) {
                this.checkDuplicateDocument(files);
            } else {
                this.uploadEnabled = true;
                this.fileListChange.emit(files);
            }
        } else {
            this.uploadEnabled = true;
            this.processingChange.emit(false);
        }
    }

    private checkDuplicateDocument(files: File[]): void {
        const request: IDuplicateDocRequest = {
            filesInfo: files.map(file => ({
                size: file.size,
                name: file.name
            })),
            buildingGuid: this.buildingId
        }

        this.documentService.checkDuplicateDocument(request)
            .subscribe({
                next: (result: string[]) => {
                    this.processingChange.emit(false);

                    if (result?.length) {
                        this.showDuplicatesModal(result, files);
                    } else {
                        this.uploadEnabled = true;
                        this.fileListChange.emit(files);
                    }
                },
                error: () => {
                    this.processingChange.emit(false);
                    this.uploadEnabled = true;
                }
            });
    }

    private showDuplicatesModal(duplicates: string[], files: File[]): void {
        const dilogRef = this.matDialog.open(DuplicatesModalComponent, {
            data: {
                title: 'Duplicates Detected',
                description: "It looks like you're uploading documents that we already have on file",
                items: duplicates,
                confirmButtonText: 'Upload',
                cancelButtonText: "Don't upload",
                question: "Do you want to upload anyway?",
            },
            panelClass: ['confirm-dialog'],
            disableClose: true
        });

        this.duplicatesModalService.setDialogVisible(true);

        dilogRef.afterClosed().subscribe({
            next: (result) => {
                this.duplicatesModalService.setDialogVisible(false);
                this.processingChange.emit(false);
                this.uploadEnabled = true;

                if (result === 'confirm') {
                    this.fileListChange.emit(files);
                } else {
                    const noDuplicatedFiles: File[] = [];
                    (Array.from(files))?.filter((file) => {
                        if (!duplicates.includes(file.name)) {
                            noDuplicatedFiles.push(file);
                        }
                    });
                    this.fileListChange.emit(noDuplicatedFiles);
                }
            },
        });
    }
}