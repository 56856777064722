import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import {
    NotificationApiService,
    NotificationModel,
    NotificationSection,
    TasksApiService,
    UserStore
} from '@quipex/shared/data';
import { GetNotificationsRequestDTO } from 'modules/shared/data/src/lib/models/notification.model';
import { Subject, takeUntil } from 'rxjs';
import { LoaderComponent } from '../loader.component';

@Component({
    selector: 'qpx-notifications',
    styleUrls: ['notification.component.scss'],
    templateUrl: 'notification.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        LoaderComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
    unreadCount!: number;
    showingKebabMenu: number | null = null;
    notifications: NotificationModel[] = [];
    notificationCount!: number;

    notificationsRequest = {} as GetNotificationsRequestDTO;
    isDropdownOpen = false;
    currentUserId!: string;
    private destroy$ = new Subject<void>(); // Subject to signal component destruction

    constructor(
        private notificationService: NotificationApiService,
        private userStore: UserStore,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef,
        private tasksService: TasksApiService
    ) { }

    ngOnInit() {
        // Subscribe to the NavigationStart event to close the dropdown when navigating
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.isDropdownOpen = false;
                this.cdr.detectChanges();
            }
        });

        this.fetchNotificaionCount();
        this.userStore.select('me').subscribe((user) => {
            this.currentUserId = user.identifier;
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    toggleNotifications() {
        this.isDropdownOpen = !this.isDropdownOpen;
        this.notificationsRequest.pageNo = 1;
        this.notificationsRequest.pageSize = 10;
        this.notificationsRequest.userId = this.currentUserId;

        this.fetchNotifications();
    }

    private fetchNotificaionCount() {
        this.notificationService.getNotificationsCount().subscribe((data) => {
            this.unreadCount = data;
            this.cdr.detectChanges();
        });
    }
    markAllAsRead() {
        this.notificationService.readAll().subscribe((data) => {
            this.unreadCount = 0;
            this.cdr.detectChanges();
        });
    }

    navigateToDetails(notification: NotificationModel): void {
        let routeExtras = '';
        let url = '';

        if (notification.section === NotificationSection.OrgAssignedTask
            || notification.section === NotificationSection.UserAssignedTask 
            || notification.section === NotificationSection.NewCriticalTask) {
            url = `/tasks`;
            this.tasksService.setBuildingId(notification.buildingId);
        } else {
            url = `/buildings/${notification.buildingIdentifier}/maintenance`;

            switch (notification.section) {
                case NotificationSection.ESMUpcominng:
                    routeExtras = 'esm';
                    break;
                case NotificationSection.ESMOverdue:
                    routeExtras = 'esm';
                    break;
                case NotificationSection.ABSSDue:
                    routeExtras = 'abss';
                    break;
                case NotificationSection.ABSSOverdue:
                    routeExtras = 'abss';
                    break;
            }
        }

        this.isDropdownOpen = false;
        this.router.navigate([url], {
            state: {
                tab: routeExtras,
                buildingId: notification.buildingId
            }
        });
    }

    markAsRead(id: number) {
        this.notificationService.delete(id).subscribe((data) => {
            this.fetchNotifications(); // reload notifications
            this.unreadCount--;
        });
    }

    isOverdue(description: string): boolean {
        if (description.includes('overdue')) return true;
        return false;
    }

    fetchNotifications() {
        this.notificationService
            .getNotifications(this.notificationsRequest)
            .subscribe((data) => {
                this.notifications = data.notifications;
                this.cdr.detectChanges();
                this.markAllAsRead(); // on open of notification dropdown we mark them all as read
            });
    }

    // Close the dropdown when clicking outside
    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {
        if (!this.elRef.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
        }
    }
}
