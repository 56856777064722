import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// used to manage the state of the advanced building search screen
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private isSearchOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isSearchOpen$: Observable<boolean> = this.isSearchOpenSubject.asObservable();

  openSearch(): void {
    this.isSearchOpenSubject.next(true);
  }

  closeSearch(): void {
    this.isSearchOpenSubject.next(false);
  }
}
