export enum BuildingTaskStatusEnum {
    NotRresolved = 1,
    Overdue = 2,
    Upcoming = 3,
    InProgress = 4,
    Resolved = 5,
    Completed = 6,
    Triage = 7,
    OverdueCompleted = 8
}

export enum BuildingTaskStatusNamesEnum {
    NotRresolved = 'Not resolved',
    Overdue = 'Overdue',
    InProgress = 'In progress',
    Upcoming = 'Upcoming',
    Completed = 'Completed',
    Resolved = 'Resolved',
    Triage = 'Triage',
    OverdueCompleted = 'Overdue (comp)'
}

export interface IBuildingTaskStatus {
    id: BuildingTaskStatusEnum;
    name: BuildingTaskStatusNamesEnum;
}

export const buildingTaskStatus: IBuildingTaskStatus[] = [
    { id: BuildingTaskStatusEnum.Triage, name: BuildingTaskStatusNamesEnum.Triage },
    { id: BuildingTaskStatusEnum.NotRresolved, name: BuildingTaskStatusNamesEnum.NotRresolved },
    { id: BuildingTaskStatusEnum.Overdue, name: BuildingTaskStatusNamesEnum.Overdue },
    { id: BuildingTaskStatusEnum.InProgress, name: BuildingTaskStatusNamesEnum.InProgress },
    { id: BuildingTaskStatusEnum.Upcoming, name: BuildingTaskStatusNamesEnum.Upcoming },
    { id: BuildingTaskStatusEnum.Resolved, name: BuildingTaskStatusNamesEnum.Resolved },
    { id: BuildingTaskStatusEnum.Completed, name: BuildingTaskStatusNamesEnum.Completed },
    { id: BuildingTaskStatusEnum.OverdueCompleted, name: BuildingTaskStatusNamesEnum.OverdueCompleted }
];


export enum BuildingTaskTypeEnum {
    ScheduleMaintenance = 1,
    GeneralMaintenance = 2,
    Defect = 3,
    AnnualStatement = 4
}

export enum BuildingTaskTypeNamesEnum {
    ScheduleMaintenance = 'Scheduled maintenance',
    GeneralMaintenance = 'General maintenance',
    Defect = 'Defect',
    AnnualStatement = 'Annual statement'
}

export interface IBuildingTaskType {
    id: BuildingTaskTypeEnum;
    name: BuildingTaskTypeNamesEnum;
}

export const buildingTaskTypes: IBuildingTaskType[] = [
    { id: BuildingTaskTypeEnum.AnnualStatement, name: BuildingTaskTypeNamesEnum.AnnualStatement },
    { id: BuildingTaskTypeEnum.Defect, name: BuildingTaskTypeNamesEnum.Defect },
    { id: BuildingTaskTypeEnum.GeneralMaintenance, name: BuildingTaskTypeNamesEnum.GeneralMaintenance },
    { id: BuildingTaskTypeEnum.ScheduleMaintenance, name: BuildingTaskTypeNamesEnum.ScheduleMaintenance }
];

export enum BuildingTaskCriticalEnum {
    Critical = 1,
    NotCritical = 2,
    NA = 3
}

export enum BuildingTaskCriticalNamesEnum {
    Critical = 'Critical',
    NotCritical = 'Not critical',
    NA = 'NA'
}

export interface IBuildingTaskCritical {
    id: BuildingTaskCriticalEnum;
    name: BuildingTaskCriticalNamesEnum;
}

export const buildingTasksCritical: IBuildingTaskCritical[] = [
    { id: BuildingTaskCriticalEnum.Critical, name: BuildingTaskCriticalNamesEnum.Critical },
    { id: BuildingTaskCriticalEnum.NotCritical, name: BuildingTaskCriticalNamesEnum.NotCritical },
    { id: BuildingTaskCriticalEnum.NA, name: BuildingTaskCriticalNamesEnum.NA }
];


export interface ITaskAssignedBuilding {
    id: number;
    buildingName: string;
}

export interface ITaskAssignedOrganisation {
    id: number;
    organizationName: string;
}

export interface ITaskAssignedUser {
    id: number;
    userName: string;
}

export interface IGetTaskListResponse {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageIndex: number;
    totalCount: number;
    totalPages: number;
    items: ITaskItem[];
}

export interface IGetTaskResponse {
    identifier: string;
    description: string;
    buildingName: string;
    buildingId: string;
    assignedOrgId: number;
    assignedUserId: number;
    assignedUserName: string;
    status: BuildingTaskStatusEnum;
    type: BuildingTaskTypeEnum;
    isCritical: BuildingTaskCriticalEnum;
    raisedDate: Date;
    age: number;
    completionDate: Date;
    accessDaysAndHours?: string;
    contactNameForAccess?: string;
    email?: string;
    phoneNumber?: string;
    documents: IGetTaskDocument[];
    notes: IGetTaskNote[];
    defectDTOs: IGetTaskDefectDto[];
    inspectionBasicDetails: IGetTaskInspectionBasicDetails;
}

export interface IGetTaskInspectionBasicDetails {
    inspectionTaskId: string;
    inspectionTaskDescription: string;
}

export interface IGetTaskDefectDto {
    id: string;
    description: string;
    isCritical: BuildingTaskCriticalEnum;
}

export interface IGetTaskNote {
    note: string;
    userName: string;
    date: Date;
}

export interface IGetTaskDocument {
    documentId: string;
    documentName: string;
}

export interface ITaskItem {
    assignedOrgId: number;
    assignedUserId: number;
    assignedUserName: string;
    building: string;
    buildingId: string;
    description: string;
    trustedDescription: any;
    id: string;
    isCritical: BuildingTaskCriticalEnum;
    status: BuildingTaskStatusEnum;
    type: BuildingTaskTypeEnum;
    isESM: boolean;
}

export interface IUpdateAssignedOrg {
    taskId: string;
    assignedOrganizationId: number;
}

export interface IUpdateAssignedUser {
    taskId: string;
    assignedUserId: number | null;
}

export interface IAddTaskPostModel {
    buildingId: number;
    type: BuildingTaskTypeEnum;
    status: BuildingTaskStatusEnum;
    isCritical: BuildingTaskCriticalEnum;
    description: string;
    assignedOrgId: number;
    assignedUserId: number;
    notes: string[];
    accessDaysAndHours: string;
    contactNameForAccess: string;
    email: string;
    phoneNumber: string;
}

export interface IUpdateDefectPostModel {
    taskId: string;
    status: BuildingTaskStatusEnum;
    completionDate: Date | null;
    assignedOrgId: number;
    assignedUserId: number;
    addedNotes: string[];
    documents: File[];
    accessDaysAndHours: string;
    contactNameForAccess: string;
    email: string;
    phoneNumber: string;
}

export interface IUpdateTaskPostModel {
    taskId: string;
    status: BuildingTaskStatusEnum;
    assignedOrgId: number;
    assignedUserId: number;
    addedNotes: string[];
    accessDaysAndHours: string;
    contactNameForAccess: string;
    email: string;
    phoneNumber: string;
}

export interface ICompleteTaskPostModel {
    taskId: string;
    status: BuildingTaskStatusEnum;
    completionDate:  Date | null;
    assignedOrgId: number;
    assignedUserId: number;
    addedNotes: string[];
    documents: File[];
    addedDefectDTOs: any;
}