import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MointoringService } from './mointoring.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private monitoringService: MointoringService,
        private zone: NgZone
    ) {}

    handleError(error: Error): void {
        // Run inside NgZone to ensure UI updates
        this.zone.run(() => {
            // Log the error
            this.monitoringService.logException(error, 3); // High severity for unhandled errors
            
            // Log additional context
            this.monitoringService.logEvent('UnhandledError', {
                errorName: error.name,
                errorMessage: error.message,
                stackTrace: error.stack,
                url: window.location.href,
                timestamp: new Date().toISOString()
            });
        });
    }
} 