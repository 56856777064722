<div class="bhc-time-chart">
    @if(isLoading()) {
        <div class="qpx-loader-wrapper">
            <qpx-loader></qpx-loader>
        </div>
    } @else {
        <div class="flex justify-between qpx-card-title">
            <span>{{ title }}</span>

            <div class="controls">
                <div *ngIf="showPortfolioCommunityDropdown">
                    <mat-form-field class="no-error">
                        <mat-select #matSelectPortfolio
                                [formControl]="portfolioCommunityCtrl"
                                (selectionChange)="portfolioCommunitySelect($event)">
                                <mat-option [value]="true">{{ chartSeriesNameEnum.PortfolioAverage }} </mat-option>
                                <mat-option [value]="false">{{ chartSeriesNameEnum.CommunityAverage }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field class="no-error">
                    <mat-select #matSelectInterval
                                [formControl]="bhcScoreIntervalCtrl"
                                (selectionChange)="onBhcScoreIntervalTypeChange($event)">
                        <mat-option *ngFor="let item of bhcScoreIntervalTypeList"
                                    [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="flex wrap justify-between">
            <ul class="flex qpx-card-links">
                <li *ngFor="let type of chartTypes; let i = index"
                    data-ng-model="chartType">
                    <div class="card-link"
                        [ngClass]="i !== bhcScorePayload.chartType - 1 ? 'inactive' :'active' "
                        (click)="chartTypeChange(type.id)">
                        {{type.name}}
                    </div>
                </li>
            </ul>

            <div class="flex wrap statistics">
                <span class="flex align-center inactive">
                    <mat-icon class="integrity-score">circle</mat-icon>
                    <span *ngIf="bhcScorePayload.chartType === 1">&nbsp;&nbsp;
                        {{chartSeriesNameEnum.IntegrityScore}}</span>
                    <span *ngIf="bhcScorePayload.chartType === 2">&nbsp;&nbsp;
                        {{chartSeriesNameEnum.RiskCultureScore}}</span>
                </span>

                @if((showPortfolioCommunityDropdown && portfolioCommunityCtrl.value) || !showPortfolioCommunityDropdown) {
                    <span *ngIf="buildingId"
                        class="flex align-center inactive">
                        <mat-icon class="portfolio-average">circle</mat-icon>&nbsp;&nbsp;
                        {{chartSeriesNameEnum.PortfolioAverage}}
                    </span>
                }

                @if((showPortfolioCommunityDropdown && !portfolioCommunityCtrl.value) || !showPortfolioCommunityDropdown) {
                    <span class="flex align-center inactive">
                        <mat-icon class="community-average">circle</mat-icon>&nbsp;&nbsp;
                        {{chartSeriesNameEnum.CommunityAverage}}
                    </span>
                }
            </div>
        </div>

        <apx-chart [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [grid]="chartOptions.grid"
                [stroke]="chartOptions.stroke"
                [yaxis]="chartOptions.yaxis"
                [legend]="chartOptions.legend"
                [markers]="chartOptions.markers"
                [tooltip]="chartOptions.tooltip">
        </apx-chart>
    }

</div>