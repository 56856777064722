import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnInit,
    inject
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
    ActivatedRoute,
    RouterLink,
    RouterLinkActive,
} from '@angular/router';
import { UtilsService } from './../../../../../../src/app/services/utils.service';

@Component({
    selector: 'qpx-building-manual',
    templateUrl: './building-manual.component.html',
    styleUrls: ['./building-manual.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        RouterLinkActive,
        MatInputModule,
        MatIconModule,
        MatProgressBarModule
    ],
})
export class BuildingManualComponent implements OnInit {
    @Input({ required: true }) buildingManualPercentage: number = 0;
    @Input({ required: true }) missingKeyItemsNo: number = 0;
    @Input({ required: true }) totalKeyItemsNo: number = 0;
    @Input({ required: true }) completedItemsNo: number = 0;
    @Input({ required: true }) canUpdate!: boolean;

    buildingGuid: any;
    progressValue: number = 0;

    private readonly route = inject(ActivatedRoute);
    private readonly utilsService = inject(UtilsService);

    ngOnInit(): void {
        this.route.parent?.paramMap.subscribe((params) => {
            this.buildingGuid = params.get('buildingGuid') ?? '';
        });

        this.setProgressValue(this.completedItemsNo);
    }

    private setProgressValue(value: number): void {
        this.progressValue = Math.ceil(this.utilsService.rangeToPercent(value, 0, this.totalKeyItemsNo) * 100);
    }
}