<div>
    <ng-container *ngIf="data.showCloseIcon">
        <h2 mat-dialog-title>
            <mat-icon (click)="onCancel()">close</mat-icon>
        </h2>
    </ng-container>

    <mat-dialog-content>
        <ng-container *ngIf="data.type !== confirmationType.Default">
            <mat-icon *ngIf="data.type === confirmationType.Warning"
                      class="material-symbols-outlined status-icon warning">warning</mat-icon>
            <mat-icon *ngIf="data.type === confirmationType.Error"
                      class="material-symbols-outlined status-icon cancel">error</mat-icon>
            <mat-icon *ngIf="data.type === confirmationType.Info"
                      class="material-symbols-outlined status-icon info">info</mat-icon>
            <mat-icon *ngIf="data.type === confirmationType.Success"
                      class="material-symbols-outlined status-icon success">check_circle</mat-icon>
        </ng-container>

        <div *ngIf="data.title"
             class="confirm-dialog-title">{{ data.title }}</div>
        <div *ngIf="data.text1">{{ data.text1 }}</div>
        <div *ngIf="data.text2">{{ data.text2 }}</div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div *ngIf="data.cancelButtonVisible"
             class="cancel-button-wrapper">
            <button mat-stroked-button
                    (click)="onCancel()">
                <mat-icon class="qpx-icon-sm">
                    close
                </mat-icon>
                {{ data.cancelButtonText || 'Cancel' }}
            </button>
        </div>
        <button mat-flat-button
                data-testId="confirmButton"
                [color]="confirmButtonColor"
                mat-dialog-close="confirm"
                cdkFocusInitial>
            {{ data.confirmButtonText || 'Confirm' }}
        </button>

    </mat-dialog-actions>
</div>