import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG_TOKEN, AppConfigModel } from './models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export abstract class ApiServiceBase {
  constructor(
    @Inject(APP_CONFIG_TOKEN) protected appConfig: AppConfigModel,
    protected http: HttpClient
  ) {}

  protected handleError(err: string): Observable<never> {
    return throwError(() => err);
  }
}
