<div class="building-integrity-score">
    <div class="qpx-card-title"> Building Integrity</div>

    @if(bhcScoreCardOverall(); as bhcScoreCardOverall){
        @if(bhcScoreCardOverall.rating > 0) {
            <div class="flex wrap justify-between flex-gap-md">
                <span class="qpx-percentage">{{bhcScoreCardOverall.medianScore}}</span>

                <div class="flex change-percentage-wrapper">
                    @if(bhcScoreCardOverall.lastQuarterScorePercentageChange !== undefined) {
                        <div class="change-percentage last-quarter"
                            [ngClass]="bhcScoreCardOverall.lastQuarterScorePercentageChange >= 0 ? 'positive' : 'negative'">

                            <span class="change-percentage-number">
                                {{ bhcScoreCardOverall.lastQuarterScorePercentageChange}}%
                            </span>

                            <span class="change-percentage-arrow-icon">
                                @if(bhcScoreCardOverall.lastQuarterScorePercentageChange >= 0) {
                                    <mat-icon class="material-symbols-outlined qpx-text-success">
                                        arrow_upward_alt
                                    </mat-icon>
                                } @else {
                                    <mat-icon class="material-symbols-outlined qpx-text-danger">
                                        arrow_downward_alt
                                    </mat-icon>
                                }
                            </span>
                            
                            <div class="change-percentage-label">Last quarter</div>
                        </div>
                    }

                    @if(bhcScoreCardOverall.portfolioAverageScorePercentageChange !== undefined) {
                        <div class="change-percentage"
                            [ngClass]="bhcScoreCardOverall.portfolioAverageScorePercentageChange >= 0 ? 'positive' : 'negative'">

                            <span class="change-percentage-number">
                                {{ bhcScoreCardOverall.portfolioAverageScorePercentageChange}}%
                            </span>

                            <span class="change-percentage-arrow-icon">
                                @if(bhcScoreCardOverall.portfolioAverageScorePercentageChange >= 0) {
                                    <mat-icon class="material-symbols-outlined qpx-text-success">
                                        arrow_upward_alt
                                    </mat-icon>
                                } @else {
                                    <mat-icon class="material-symbols-outlined qpx-text-danger">
                                        arrow_downward_alt
                                    </mat-icon>
                                }
                            </span>

                            <div class="change-percentage-label">Portfolio Avg</div>
                        </div>
                    }
                </div>
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="noDataMessage"></ng-container>
        }
    }
</div>

<div class="risk-culture-rating">
    <div class="qpx-card-title">Risk Culture</div>

    @if(bhcCultureScoreCardOverall(); as bhcCultureScoreCardOverall){
        @if(bhcCultureScoreCardOverall.rating) {
            <div class="flex wrap justify-between align-center flex-gap-md">
                <qpx-health-rating class="mat-stars-2x"
                                [rating]="bhcCultureScoreCardOverall.rating">
                </qpx-health-rating>

                <div class="flex change-percentage-wrapper">

                    @if(bhcCultureScoreCardOverall.lastQuarterScorePercentageChange) {
                    <div class="change-percentage last-quarter"
                        [ngClass]="bhcCultureScoreCardOverall.lastQuarterScorePercentageChange >= 0 ? 'positive' : 'negative'">

                        <span class="change-percentage-number">
                            {{ bhcCultureScoreCardOverall.lastQuarterScorePercentageChange}}%
                        </span>

                        <span class="change-percentage-arrow-icon">
                            @if(bhcCultureScoreCardOverall.lastQuarterScorePercentageChange >= 0) {
                            <mat-icon class="material-symbols-outlined qpx-text-success">
                                arrow_upward_alt
                            </mat-icon>
                            } @else {
                            <mat-icon class="material-symbols-outlined qpx-text-danger">
                                arrow_downward_alt
                            </mat-icon>
                            }
                        </span>

                        <div class="change-percentage-label">Last quarter</div>
                    </div>
                    }

                    @if(bhcCultureScoreCardOverall.portfolioAverageScorePercentageChange !== undefined) {
                    <div class="change-percentage"
                        [ngClass]="bhcCultureScoreCardOverall.portfolioAverageScorePercentageChange >= 0 ? 'positive' : 'negative'">

                        <span class="change-percentage-number">
                            {{ bhcCultureScoreCardOverall.portfolioAverageScorePercentageChange }}%
                        </span>

                        <span class="change-percentage-arrow-icon">
                            @if(bhcCultureScoreCardOverall.portfolioAverageScorePercentageChange >= 0) {
                            <mat-icon class="material-symbols-outlined qpx-text-success">
                                arrow_upward_alt
                            </mat-icon>
                            }
                            @if(bhcCultureScoreCardOverall.portfolioAverageScorePercentageChange < 0)
                            {
                            <mat-icon
                            class="material-symbols-outlined qpx-text-danger">
                                arrow_downward_alt
                                </mat-icon>
                                }
                        </span>

                        <div class="change-percentage-label">Portfolio Avg</div>
                    </div>
                    }
                </div>
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="noDataMessage"></ng-container>
        }
    }
</div>

<ng-template #noDataMessage>
    <div class="no-data-message">No data available.</div>
</ng-template>