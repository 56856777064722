import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Inject,
    OnInit
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DuplicatesModalService } from './duplicates-modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IGenericDialogData {
    items?: string[];
    title?: string;
    description?: string;
    question?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
}

@Component({
    selector: 'qpx-duplicates-modal',
    templateUrl: './duplicates-modal.component.html',
    styleUrls: ['./duplicates-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ]
})
export class DuplicatesModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DuplicatesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IGenericDialogData,
        private destroyRef: DestroyRef,
        private duplicatesModalService: DuplicatesModalService
    ) { }

    ngOnInit(): void {
        this.duplicatesModalService.dialogDataChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data: IGenericDialogData | null) => {
                if (data) {
                    this.data = { ...this.data, ...data };
                }
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
