<form [formGroup]="form">

    <mat-label [ngClass]="{'disabled' : form.disabled}">Address Search</mat-label>

    <mat-form-field class="w-100">

        <mat-icon matPrefix
                  [ngClass]="{'disabled' : form.disabled}">search</mat-icon>

        <input type="text"
               name="address"
               spellcheck="false"
               autocomplete="off"
               placeholder="Start typing building address..."
               matInput
               maxlength="50"
               #addressSearchEl
               [matAutocomplete]="addressSearch"
               [formControl]="addressSearchCtrl">

        <mat-icon matSuffix
                  class="cursor-pointer"
                  (click)="addressSearchCtrl.setValue('')">close</mat-icon>

        <mat-autocomplete autoActiveFirstOption
                          #addressSearch="matAutocomplete"
                          [displayWith]="displayFn"
                          (optionSelected)="onAddressSelected($event)">

            <mat-option *ngFor="let item of filteredAddresses$ | async as searchResult"
                        [value]="item"
                        [disabled]="item.id === '0'">

                <span class="display-address">
                    {{ item.id === "0" ? 'No Search Results...' : item.address}}
                </span>
            </mat-option>

        </mat-autocomplete>
    </mat-form-field>
</form>