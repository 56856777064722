import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'qpx-health-rating',
    standalone: true,
    templateUrl: './health-rating.component.html',
    imports: [CommonModule, MatIconModule]
})
export class HealthRatingComponent {
    @Input() rating!: number;
    @Input() starCount = 5;
}