import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Input,
    OnInit,
    ViewChild,
    inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import {
    DialogService,
    User,
    UserStore
} from '@quipex/shared/data';
import { BulkUploadFilesComponent } from '../bulk-upload-files/bulk-upload-files.component';

@Component({
    selector: 'qpx-bulk-upload-files-modal',
    templateUrl: './bulk-upload-files-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        BulkUploadFilesComponent
    ],
})
export class BulkUploadFilesModalComponent implements OnInit {
    @Input() buildingId!: string;

    @ViewChild('bulkUploadFiles') bulkUploadFiles!: BulkUploadFilesComponent;

    isProcessing = false;
    showBuildingReviewScreen = false;

    private readonly destroyRef = inject(DestroyRef);
    private readonly dialogService = inject(DialogService);
    private readonly userStore = inject(UserStore);

    ngOnInit(): void {
        this.getUser();

        this.dialogService.isProcessing$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isProcessing) => (this.isProcessing = isProcessing));

        // on dialog Cancel
        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog());

        // on dialog Save - Handle submit
        this.dialogService.requestConfirm$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.bulkUploadFiles?.handleUploadDocuments();
            });
    }

    onIsProcessingChange(isProcessing: boolean): void {
        this.dialogService.setIsProcessing(isProcessing);
    }

    onCanConfirmChange(canConfirm: boolean): void {
        this.dialogService.setCanConfirm(canConfirm);
    }

    onUploadFinished(): void {
        this.dialogService.closeDialog(true);
    }

    private getUser(): void {
        this.userStore.select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user: User) => {
                const isBuilder = user?.role?.toUpperCase()?.includes('BUILDER') ?? false;
                const isOwner = user?.role?.toUpperCase()?.indexOf('OWNER') === 0;
                this.showBuildingReviewScreen = isBuilder || isOwner;
            });
    }
}
