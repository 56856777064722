import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DuplicatesModalComponent } from '../duplicates-modal/duplicates-modal.component';
import { BehaviorSubject } from 'rxjs';

export interface IGenericModalConfig {
    title: string;
    description: string;
    items: string[];
    confirmButtonText?: string;
    cancelButtonText?: string;
}

@Injectable({
    providedIn: 'root'
})
export class GenericModalService {
    private dialogVisible = new BehaviorSubject<boolean>(false);
    dialogVisible$ = this.dialogVisible.asObservable();

    constructor(private dialog: MatDialog) {}

    showAddressMismatchModal(files: { file: { name: string } }[] | { name: string }[]): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(DuplicatesModalComponent, {
                data: {
                    title: 'Address Mismatch',
                    description: "The following document(s) appear to be for addresses that differ from this building manual. Do you still want to upload?",
                    items: files.map(file => 'file' in file ? file.file.name : file.name),
                    confirmButtonText: 'Upload',
                    cancelButtonText: "Don't upload"
                },
                panelClass: ['confirm-dialog'],
                disableClose: true
            });

            this.setDialogVisible(true);

            dialogRef.afterClosed().subscribe({
                next: (result) => {
                    this.setDialogVisible(false);
                    resolve(result === 'confirm');
                },
            });
        });
    }

    showGenericModal(config: IGenericModalConfig): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(DuplicatesModalComponent, {
                data: config,
                panelClass: ['confirm-dialog'],
                disableClose: true
            });

            this.setDialogVisible(true);

            dialogRef.afterClosed().subscribe({
                next: (result) => {
                    this.setDialogVisible(false);
                    resolve(result === 'confirm');
                },
            });
        });
    }

    setDialogVisible(isVisible: boolean): void {
        this.dialogVisible.next(isVisible);
    }
} 