import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
    FormArray, FormControl, FormGroup, NonNullableFormBuilder,
    ReactiveFormsModule, Validators
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
    DialogService, DocDto, DocumentTypeEnum, DocumentTypeNamesEnum, documentTypesOptions
} from "@quipex/shared/data";
import { distinctUntilChanged } from "rxjs";
import { TagsComponent } from "../../documents/tags/tags.component";

@Component({
    selector: 'qpx-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        TagsComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileListComponent {
    @Input() set files(files: DocDto[]) {
        this._files = files;
        this.form = this.configureForm(files);
    }

    fileListChange = output<DocDto[]>();

    form!: FormGroup;

    documentTypesOptions = documentTypesOptions;

    private _files!: DocDto[];

    get filesCtrl(): FormArray {
        return this.form?.controls['files'] as FormArray;
    }

    private readonly destroyRef = inject(DestroyRef);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly dialogService = inject(DialogService);

    onTagSelectionChange(): void {
        this.updateFilesWithFormValues();
        this.fileListChange.emit(this._files);
    }

    private configureForm(files: DocDto[]): FormGroup {
        const form = this.formBuilder.group({});
        const formArray = new FormArray<any>([]);
        form.addControl('files', formArray);

        files.forEach((file) => formArray.push(this.configureLineItem(file)));

        form.markAllAsTouched();

        setTimeout(() => this.dialogService.setCanConfirm(this.form.valid));

        form.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.dialogService.setCanConfirm(this.form.valid);

                    this.updateFilesWithFormValues();
                    this.fileListChange.emit(this._files);
                }
            })

        return form;
    }

    private configureLineItem(file: DocDto): FormGroup {
        const formGroup = this.formBuilder.group({
            name: new FormControl(file.file.name, [Validators.required]),
            type: new FormControl(file.documentType ? this.getSelectedTypeOption(file.documentType) : null),
            tags: new FormControl(file.autoTag)
        });

        return formGroup;
    }

    private getSelectedTypeOption(type: DocumentTypeEnum): { id: DocumentTypeEnum, name: DocumentTypeNamesEnum } | null {
        if (!type) { return null; }
        const result = documentTypesOptions.find((option) => option.id === type);
        return result ?? null;
    }

    private updateFilesWithFormValues(): void {
        this._files.forEach((file, index) => {
            const formControl = this.filesCtrl.controls[index] as any;
            if (formControl.controls['name'].value && formControl.controls['name'].value !== file.file.name) {
                // create new file with new file name
                file.file = this.createNewFile(file.file, formControl.controls['name'].value);
            }
            file.documentType = formControl.controls['type'].value?.id ?? null;
            file.autoTag = formControl.controls['tags'].value;
        });
    }

    private createNewFile(file: File, newFileName: string): File {
        return new File([file], newFileName, { type: file.type });
    }
}