import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    signal
} from '@angular/core';

@Component({
    selector: 'qpx-show-more',
    templateUrl: './show-more.component.html',
    styleUrls: ['./show-more.component.scss'],
    standalone: true,
    imports: [
        CommonModule
    ],
})
export class ShowMoreComponent {
    @Input() items!: string[];
    @Input() type: 'label' | 'tag' = 'label';

    showAll = signal(false);

    onShowMoreClick(): void {
        this.showAll.set(true);
    }

}
