import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
    AzureFunctionApiService,
    DialogService,
    DocumentApiService,
    DocumentDetails,
    DocumentTypeEnum,
    DocumentTypeNamesEnum,
    documentTypesOptions,
    UserStore
} from '@quipex/shared/data';
import { distinctUntilChanged, finalize } from 'rxjs';
import { LoaderComponent } from '../../loader.component';
import { TagsComponent } from '../tags/tags.component';

@Component({
    selector: 'qpx-document-edit-modal',
    templateUrl: './document-edit-modal.component.html',
    styleUrls: ['./document-edit-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        LoaderComponent,
        TagsComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentEditModalComponent implements OnInit {
    @Input() docGuid!: string;

    isLoading = signal(true);
    documentDetails = signal(new DocumentDetails());
    form!: FormGroup;

    documentTypesOptions = documentTypesOptions;

    private _currentUserId!: number;

    private readonly cdr = inject(ChangeDetectorRef);
    private readonly destroyRef = inject(DestroyRef);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly dialogService = inject(DialogService);
    private readonly documentService = inject(DocumentApiService);
    private readonly userStore = inject(UserStore);
    private readonly snackBar = inject(MatSnackBar);
    private readonly azFunctionService = inject(AzureFunctionApiService);

    ngOnInit(): void {
        if (!this.docGuid) { return; }

        this.getUserInfo();
        this.fetchDocumentDetails(this.docGuid);

        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog(false));

        this.dialogService.requestConfirm$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.updateEvidenceDetails());
    }

    fetchDocumentDetails(docGuid: string): void {
        this.documentService.getDocumentDetailsById(docGuid)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe((response: DocumentDetails) => {
                this.documentDetails.set(response);
                this.form = this.configureForm(response);
                this.cdr.markForCheck();
            });
    }

    private configureForm(documentDetails: DocumentDetails): FormGroup {
        const formGroup = this.formBuilder.group({
            name: new FormControl(documentDetails.name ?? '', [Validators.required]),
            type: new FormControl(this.getSelectedTypeOption(documentDetails.documentType)),
            tags: new FormControl(documentDetails.tags ?? [])
        });

        setTimeout(() => {
            this.dialogService.setCanConfirm(this.form.valid);
        }, 0);

        formGroup.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.dialogService.setCanConfirm(this.form.valid);
                }
            })

        return formGroup;
    }

    private getSelectedTypeOption(type: DocumentTypeEnum): { id: DocumentTypeEnum, name: DocumentTypeNamesEnum } | null {
        if (!type) { return null; }
        const result = documentTypesOptions.find((option) => option.id === type);
        return result ?? null;
    }

    private updateEvidenceDetails(): void {
        const updatedDetails = this.documentDetails();
        updatedDetails.userId = this._currentUserId;
        updatedDetails.name = this.form.controls['name'].value;
        updatedDetails.documentType = this.form.controls['type'].value?.id ?? null;
        updatedDetails.tags = this.form.controls['tags'].value ?? [];

        this.documentService.updateDocumentDetails(updatedDetails)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.azFunctionService.runSearchIndexer()
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe();
                    this.dialogService.closeDialog(true);
                    this.showToaster('Document updated successfully', 'success');
                },
                error: () => this.showToaster('Document update failed', 'danger')
            });
    }

    private getUserInfo(): void {
        this.userStore.select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user) => {
                this._currentUserId = user.internalId;
            });
    }

    private showToaster(title: string, panelClass: string): void {
        this.snackBar.open(title, panelClass === 'success' ? 'check_circle' : 'error', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: panelClass,
            duration: 1500
        });
    }
}
