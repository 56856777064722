import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import {
    IAssetRegisterPostModel, IAssetRegisterPutModel, IAssetRegistersDetails,
    IGeAssetRegisterResponse
} from '../models/typings/asset-register.interface';

@Injectable({
    providedIn: 'root'
})
export class AssetRegistersApiService extends ApiServiceBase {
    private apiUserBase = `${this.appConfig.apiBase}/api/asset-registers`;

    getAssetRegisters(buildingId: string, searchTerm: string, pageNo: number, pageSize: number): Observable<IGeAssetRegisterResponse> {
        const url = `${this.apiUserBase}?buildingId=${buildingId}&searchTerm=${searchTerm}&pageNo=${pageNo}&pageSize=${pageSize}`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssetRegisterDetails(assetId: string): Observable<IAssetRegistersDetails> {
        const url = `${this.apiUserBase}/${assetId}`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    createAssetRegister(model: IAssetRegisterPostModel): Observable<any> {
        const url = `${this.apiUserBase}`;
        const formDate = this.buildPostFormData(model);

        return this.http.post<any>(url, formDate)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateAssetRegister(model: IAssetRegisterPutModel): Observable<any> {
        const url = `${this.apiUserBase}`;
        const formDate = this.buildPutFormData(model);

        return this.http.put<any>(url, formDate)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    private buildPutFormData(model: IAssetRegisterPutModel): FormData {
        const formData = new FormData();
        
        this.appendBasicFields(formData, model);
        this.appendDocumentIds(formData, model);
        this.appendNewDocuments(formData, model);
        
        return formData;
    }
    
    private appendBasicFields(formData: FormData, model: IAssetRegisterPutModel): void {
        formData.append('assetId', model.assetId ?? '');
        formData.append('name', model.name);
        formData.append('category', model.category?.toString() ?? '');
        formData.append('criticality', model.criticality?.toString() ?? '');
        formData.append('condition', model.condition?.toString() ?? '');
        formData.append('endOfLifeDate', model.endOfLifeDate?.toISOString() ?? '');
        formData.append('description', model.description ?? '');
        formData.append('manufacturer', model.manufacturer ?? '');
        formData.append('model', model.model ?? '');
        formData.append('SerialNo', model.serialNo ?? '');
        formData.append('warranty', model.warranty ?? '');
        formData.append('dateOfPurchase', model.dateOfPurchase?.toISOString() ?? '');
        formData.append('costOfPurchase', model.costOfPurchase?.toString() ?? '');
        formData.append('location', model.location ?? '');
        formData.append('expectedReplacementCost', model.expectedReplacementCost?.toString() ?? '');
    }
    
    private appendDocumentIds(formData: FormData, model: IAssetRegisterPutModel): void {
        if (model.existingDocumentIds?.length) {
            model.existingDocumentIds.forEach((id) => {
                formData.append('existingDocumentIds', id.toString());
            });
        }
    }
    
    private appendNewDocuments(formData: FormData, model: IAssetRegisterPutModel): void {
        if (model.newDocuments?.length) {
            model.newDocuments.forEach((file) => {
                formData.append('newDocuments', file);
            });
        } else {
            formData.append('newDocuments', '');
        }
    }

    private buildPostFormData(model: IAssetRegisterPostModel): FormData {
        const formData = new FormData();
    
        this.appendBasicPostFields(formData, model);
        this.appendDocuments(formData, model);
    
        return formData;
    }
    
    private appendBasicPostFields(formData: FormData, model: IAssetRegisterPostModel): void {
        formData.append('buildingId', model.buildingId.toString() ?? '');
        formData.append('name', model.name);
        formData.append('category', model.category?.toString() ?? '');
        formData.append('criticality', model.criticality?.toString() ?? '');
        formData.append('condition', model.condition?.toString() ?? '');
        formData.append('endOfLifeDate', model.endOfLifeDate?.toISOString() ?? '');
        formData.append('description', model.description ?? '');
        formData.append('manufacturer', model.manufacturer ?? '');
        formData.append('model', model.model ?? '');
        formData.append('SerialNo', model.serialNo ?? '');
        formData.append('warranty', model.warranty ?? '');
        formData.append('dateOfPurchase', model.dateOfPurchase?.toISOString() ?? '');
        formData.append('costOfPurchase', model.costOfPurchase?.toString() ?? '');
        formData.append('location', model.location ?? '');
        formData.append('expectedReplacementCost', model.expectedReplacementCost?.toString() ?? '');
    }
    
    private appendDocuments(formData: FormData, model: IAssetRegisterPostModel): void {
        if (model.documents?.length) {
            model.documents.forEach((file) => {
                formData.append('documents', file);
            });
        }
    }
}
